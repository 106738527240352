<script>
import AppBannerSSK from '@/components/shared/BannerSSK.vue';
import HeaderMenuSSK from '@/components/shared/AppHeaderSSK.vue';
import Tutorial from '@/components/shared/TimelineTutorial.vue';
import AppFooter from '@/components/shared/AppFooterSSK.vue';
import feather from 'feather-icons';

export default {
	name: 'Sousenkyo',
	components: {
		HeaderMenuSSK,
		AppBannerSSK,
		Tutorial,
		AppFooter,
	},
	data() {
		return {
			isOpen: false,
			isScrolled: false,
			showNewCard: false, // Menyimpan state untuk menampilkan atau menyembunyikan card baru
			showNewCard2: false,
			showNewCard3: false,
			socials: [
				{
					id: 1,
					name: 'Website',
					icon: 'globe',
					url: 'https://jkt48.com/member/detail/id/221/',
				},
				{
					id: 2,
					name: 'Instagram',
					icon: 'instagram',
					url: 'https://instagram.com/jkt48.oniel',
				},
				{
					id: 3,
					name: 'Twitter',
					icon: 'twitter',
					url: 'https://twitter.com/c_onieljkt48',
				},
				{
					id: 4,
					name: 'Tiktok',
					icon: 'music',
					url: 'https://tiktok.com/@onieljkt48',
				},
			],
		};

	},
	mounted() {
		window.addEventListener('scroll', this.handleScroll);
		feather.replace();
	},
	updated() {
		feather.replace();
	},
	methods: {
		handleScroll() {
			// Menandai apakah halaman telah di-scroll ke bawah untuk menambahkan background pada header
			this.isScrolled = window.scrollY > 50;
		},
		toggleCard() {
			this.showNewCard = !this.showNewCard; // Mengubah state showNewCard ketika card diklik
		},
		toggleCard2() {
			this.showNewCard2 = !this.showNewCard2; // Mengubah state showNewCard ketika card diklik
		},
		toggleCard3() {
			this.showNewCard3 = !this.showNewCard3; // Mengubah state showNewCard ketika card diklik
		},
	},
	beforeUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}
};
</script>

<template>
	<!-- Banner -->
	<HeaderMenuSSK
		:class="['absolute top-0 md:left-36 z-50 w-full items-center', isScrolled ? 'bg-transparent' : 'bg-transparent']" />

	<!-- Banner -->
	<AppBannerSSK />



	<section id="default-font-page">
		<h1 class="text-5xl font-semibold text-[#1C5DDC] dark:text-white text-center my-10">Sorotan Utama Oniel</h1>

		<div class="w-full max-w-screen-lg mx-auto p-3 pb-10">
			<div class="-m-1 flex flex-wrap md:-m-2">
				<div class="flex w-1/2 flex-wrap">
					<div class="w-1/2 p-1 md:p-2 relative">
						<img alt="gallery" class="block h-full w-full rounded-lg object-cover object-center"
							src="@/assets/images/relaxrh21.jpg" />
						<div
							class="absolute bottom-0 left-0 bg-black bg-opacity-50 text-white p-2 m-1 md:m-2 text-sm font-general-medium rounded-lg">
							Relax - Top 4 JKT48 Request Hour 2021
						</div>
					</div>
					<div class="w-1/2 p-1 md:p-2 relative">
						<img alt="gallery" class="block h-full w-full rounded-lg object-cover object-center"
							src="@/assets/images/galleryssk/onielslapshow.jpg" />
						<div
							class="absolute bottom-0 left-0 bg-black bg-opacity-50 text-white p-2 m-1 md:m-2 text-sm font-general-medium rounded-lg">
							Slap Show: Ramadan Talkshow (2023)
						</div>
					</div>
					<div class="w-full p-1 md:p-2 relative">
						<img alt="gallery" class="block h-full w-full rounded-lg object-cover object-center"
							src="@/assets/images/galleryssk/oniel1.jpeg" />
						<div
							class="absolute bottom-0 left-0 bg-black bg-opacity-50 text-white p-2 m-1 md:m-2 text-sm font-general-medium rounded-lg">
							Special Performance Video: Langit Biru Cinta Searah
						</div>
					</div>
				</div>
				<div class="flex w-1/2 flex-wrap">
					<div class="w-full p-1 md:p-2">
						<img alt="gallery" class="block h-max w-full rounded-lg object-contain object-center"
							src="@/assets/images/galleryssk/posterpb.jpg" />
					</div>
					<div class="w-full p-1 md:p-2 relative">
						<img alt="gallery" class="block h-min w-full rounded-lg object-contain object-center"
							src="@/assets/images/galleryssk/sts2024.jpg" />
						<div
							class="absolute bottom-0 left-0 bg-black bg-opacity-50 text-white p-2 m-1 md:m-2 text-sm font-general-medium rounded-lg">
							Seitansai Oniel 2024 #SereneSkies
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="w-full max-w-screen-lg mx-auto p-3" id="kampanye" @click="toggleCard">
			<div
				class="grid grid-cols-1 md:grid-cols-2 border border-gray dark:border-white hover:bg-[#1C5DDC] hover:dark:bg-[#1C5DDC] text-black dark:text-white hover:text-white shadow-md rounded-lg overflow-hidden mx-full my-5 pr-8 h-auto md:h-72 ease-in duration-300">
				<div>
					<img src="@/assets/images/onielrts1.jpg"
						class="hidden md:block w-full h-72 object-cover object-top">
				</div>
				<div class="p-4 md:p-10">
					<p class="text-left text-md lg:text-xl mt-2 mr-20">
						Filosofi
					</p>
					<h2 class="text-xl lg:text-3xl font-general-bold text-left">SOSOK di Single Ke-26 JKT48</h2>
					<p class="text-justify text-md mt-2 mr-20">
						Mengambil pemaknaan SSK sebagai <b>"SOSOK"</b>, bermakna bahwa dirinya ingin menjadi sosok yang
						tampil di Pemilihan Member Single Ke-26 JKT48.
						Menggunakan <i>tagline</i> <b>#ONTIME</b> yang dapat diartikan sebagai On Time (Tepat Waktu)
						ataupun Oniel Time yang <i>timing</i>-nya dapat dirasakan oleh semua pendukungnya.
					</p>
				</div>
			</div>
		</div>

		<div v-if="showNewCard" class="w-full max-w-screen-lg mx-auto p-4">
			<iframe class="w-full xl:max-w-screen-lg rounded-lg aspect-video"
				src="https://www.youtube-nocookie.com/embed/ev_S9Wl8s9k?si=E8cTdkqTOyi6UGSS"
				title="YouTube video player" frameborder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
		</div>

		<div class="w-full max-w-screen-lg mx-auto p-3" id="whyoniel">
			<h1 class="text-5xl font-semibold text-[#1C5DDC] dark:text-white text-center my-10">Berangkat dari Sebuah
				Mimpi</h1>
			<!-- Card List Container -->
			<div class="space-y-6">
				<!-- Card 1 -->
				<router-link to="/onieland-recap">
					<div
						class="grid grid-cols-1 md:grid-cols-2 border border-gray dark:border-white shadow-md rounded-lg overflow-hidden mx-full my-5 pl-10 ease-in duration-150 hover:bg-[#1C5DDC] hover:dark:bg-[#1C5DDC] text-black dark:text-white hover:text-white">
						<div class="p-4">
							<h2 class="text-3xl font-semibold text-left">#OnieLand: Konten
								Cover Lagu</h2>
							<p class="text-left text-xl mt-2 mr-20">
								Kemampuan bernyanyi dan memainkan gitar dan ukulele tak perlu diragukan lagi.
								Konten <b>#OnieLand</b> menjadi bukti bahwa dia layak untuk maju. Klik untuk lihat
								informasi rekap lengkap kami
							</p>
						</div>
						<div>
							<img src="@/assets/images/whyoniel-1.png" alt="Image 2" class="w-full h-48 object-cover">
						</div>
					</div>
				</router-link>
				<!-- Card 1 -->
				<router-link to="/botoniel">
					<div
						class="grid grid-cols-1 md:grid-cols-2 border border-gray dark:border-white shadow-md rounded-lg overflow-hidden mx-full my-5 pl-10 ease-in duration-150 hover:bg-[#1C5DDC] hover:dark:bg-[#1C5DDC] text-black dark:text-white hover:text-white">
						<div class="p-4">
							<h2 class="text-3xl font-semibold text-left">Master of Dad Jokes
							</h2>
							<p class="text-left text-xl mt-2 mr-20">
								Kepiawaiannya bermain kata dan memeriahkan suasana, membuat kita semua tertawa dan jatuh
								cinta dengannya.
								Kami merangkum kumpulan humor terbaiknya dalam <b>BotOniel</b> yang bisa diakses di
								sini.
							</p>
						</div>
						<div>
							<img src="@/assets/images/whyoniel-6.png" alt="Image 2" class="w-full h-48 object-contain">
						</div>
					</div>
				</router-link>
				<!-- Card 2 -->
				<div
					class="grid grid-cols-1 md:grid-cols-2 border border-gray dark:border-white shadow-md rounded-lg overflow-hidden mx-full my-5 pl-10 ease-in duration-150 hover:bg-[#1C5DDC] hover:dark:bg-[#1C5DDC] text-black dark:text-white hover:text-white">
					<div class="p-4 align-middle">
						<h2 class="text-3xl font-semibold text-left">(Almost) 300 Show Teater
						</h2>
						<p class="text-left text-xl mt-2 mr-20">Mendapatkan kepercayaan
							untuk tampil sebagai yang terdepan di sebuah pertunjukan grup
							dengan semangat positif yang terasa ke seluruh penonton
						</p>
					</div>
					<div>
						<img src="@/assets/images/whyoniel-2.png" alt="Image 2" class="w-1/2 h-48 ml-32 object-cover">
					</div>
				</div>
				<!-- Card 3 -->
				<router-link to="/what-song-oniel-play">
					<div
						class="grid grid-cols-1 md:grid-cols-2 border border-gray dark:border-white shadow-md rounded-lg overflow-hidden mx-full my-5 pl-10 ease-in duration-150 hover:bg-[#1C5DDC] hover:dark:bg-[#1C5DDC] text-black dark:text-white hover:text-white">
						<div class="p-4 align-middle">
							<h2 class="text-3xl font-semibold text-left">Showroom/IDN Live
								Playlist</h2>
							<p class="text-left text-xl mt-2 mr-20">Pengetahuannya soal
								musik tidak perlu diragukan lagi.
								Wawasan lagunya memberikanmu perspektif baru untuk playlist harianmu. Kami tentu
								merangkumnya di <b>#WhatSongOnielPlay</b>
							</p>
						</div>
						<div>
							<img src="@/assets/images/whyoniel-3.png" alt="Image 2"
								class="w-1/2 h-48 ml-32 object-cover">
						</div>
					</div>
				</router-link>
				<!-- Card 4 -->
				<div
					class="grid grid-cols-1 md:grid-cols-2 border border-gray dark:border-white shadow-md rounded-lg overflow-hidden mx-full my-5 pl-10 ease-in duration-150 hover:bg-[#1C5DDC] hover:dark:bg-[#1C5DDC] text-black dark:text-white hover:text-white">
					<div class="p-4 align-middle">
						<h2 class="text-3xl font-semibold text-left">Slap Show: Ramadan Show
						</h2>
						<p class="text-left text-xl mt-2 mr-20">Berduet dengan Olla
							sebagai partner host,
							kemampuannya dalam membawakan acara menjadikan acara Ramadan ini menjadi acara yang paling
							disukai.
						</p>
					</div>
					<div>
						<img src="@/assets/images/whyoniel-4.png" alt="Image 2" class="w-1/2 h-48 ml-32 object-contain">
					</div>
				</div>
				<!-- Card 5 -->
				<div
					class="grid grid-cols-1 md:grid-cols-2 border border-gray dark:border-white shadow-md rounded-lg overflow-hidden mx-full my-5 pl-10 ease-in duration-150 hover:bg-[#1C5DDC] hover:dark:bg-[#1C5DDC] text-black dark:text-white hover:text-white">
					<div class="p-4 align-middle">
						<h2 class="text-3xl font-semibold text-left">A friendly and
							positive-vibe persona</h2>
						<p class="text-left text-xl mt-2 mr-20">Pribadi yang selalu
							berusaha
							membawa vibes positif untuk sekitarnya, termasuk saat berjumpa di bilik Meet & Greet/Video
							Call. Mungkin
							saja dia mengingat namamu!
						</p>
					</div>
					<div>
						<img src="@/assets/images/whyoniel-5.png" alt="Image 2" class="w-1/2 h-48 ml-32 object-cover">
					</div>
				</div>
			</div>
		</div>
	</section>

	<div class="w-full max-w-screen-lg mx-auto p-8">
		<button @click="toggleCard3" id="howtovote"
			class="inline-flex justify-center items-center py-3 px-5 text-base font-general-bold text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900">
			Tutorial Vote Oniel di SSK 2024
		</button>
	</div>

	<Tutorial v-if="showNewCard3" />

	<div class="w-full max-w-screen-lg mx-auto p-3" id="gerakbersama">
		<div
			class="grid grid-cols-1 md:grid-cols-2 border border-gray dark:border-white hover:bg-[#1C5DDC] hover:dark:bg-[#1C5DDC] text-black dark:text-white hover:text-white shadow-md rounded-lg overflow-hidden mx-full my-5 pr-8 h-auto md:h-72 ease-in duration-300">
			<div>
				<img src="@/assets/images/background-images/oniel-4.jpeg"
					class="hidden md:block w-full h-72 object-cover object-top">
			</div>
			<div class="p-4 md:p-10">
				<h2 class="text-xl lg:text-3xl font-general-bold text-left">Menuju Cakrawala</h2>
				<p class="text-justify text-md lg:text-xl mt-2 mr-20">
					Agenda Pemilihan Member Single Ke-26 JKT48 adalah sebuah momen pembuktian untuk melewati tantangan
					baru bagi
					mimpi-mimpi mereka. Dengan kolaborasi dan kekompakan seluruh Oniel-oshi, Oniel akan menggapai
					mimpinya dan membanggakan kita semua.
				</p>
			</div>
		</div>
	</div>

	<div class="w-full max-w-screen-lg mx-auto p-3 pb-10">
		<!-- Grid container untuk card -->
		<div class="grid grid-cols-1 gap-4 sm:grid-cols-1 lg:grid-cols-3">
			<!-- Card 1 -->
			<div class="capitalize leading-none text-xl rounded-lg px-10 py-5 bg-blue-700 text-white w-full max-w-lg">
				<h2 class="text-2xl font-general-bold mb-4">1st Pre-Result</h2>
				<p class="text-white font-general-medium">12th Rank - 8.270 Vote</p>
			</div>

			<!-- Card 2 -->
			<div
				class=" capitalize leading-none text-xl rounded-lg px-10 py-5 bg-blue-700 text-white w-full max-w-lg">
				<h2 class="text-2xl font-general-bold mb-4">2nd Pre-Result</h2>
				<p class="text-white font-general-medium">14th Rank - 20.902 Vote</p>
			</div>

			<!-- Card 3 -->
			<div
				class=" capitalize leading-none text-xl rounded-lg px-10 py-5 bg-emerald-500 text-white w-full max-w-lg">
				<h2 class="text-2xl font-general-bold mb-4">Final Result</h2>
				<p class="text-white font-general-medium">?</p>
			</div>
		</div>
	</div>


	<div class="block sm:flex sm:gap-5 w-full max-w-screen-lg mx-auto my-5 sm:my-10 p-5 rounded-lg shadow-lg border dark:border-white">
		<!-- About profile image -->
		<div class="w-full sm:w-1/4 mb-7 sm:mb-0 sm:items-center">
			<img src="@/assets/images/galleryssk/oniel7.jpeg" class="rounded-xl w-96 justify-center md:justify-left"
				alt="" />
			<p class="text-center font-general-medium text-2xl text-ternary-dark dark:text-ternary-light mb-2 mt-5">
				Mengenal Lebih Jauh
			</p>
			<div class="flex items-center gap-5 mt-5 justify-center">
				<a v-for="social in socials" :key="social.id" :href="social.url" target="__blank"
					aria-label="Share Project"
					class="bg-ternary-light dark:bg-ternary-dark text-gray-400 hover:text-primary-dark dark:hover:text-primary-light p-2 rounded-lg shadow-sm duration-500"><i
						:data-feather="social.icon" class="w-4 lg:w-5 h-4 lg:h-5"></i></a>
			</div>
		</div>
		<div class="w-full sm:w-3/4 text-justify align-center flex flex-col justify-center">
			<h2 class="text-2xl font-general-bold text-blue-600 mb-2">Tentang Oniel</h2>
			<p class="text-gray-700 dark:text-white mb-4 font-general-medium">
				<b>Seperti Teka-teki, kalian akan selalu penasaran denganku. Aku Oniel!</b> <br> <br> Cornelia Syafa
				Vanisa, atau yang
				dikenal sebagai Oniel merupakan member generasi ke-8 dari grup idola Indonesia JKT48. Oniel bergabung
				dengan JKT48 pertama kali di Academy Class B pada 27 April 2019 dan dipromosikan ke Academy Class A pada
				26 Mei 2019. Oniel resmi dipromosikan ke Tim T pada 1 Juni 2020 sebelum memasuki formasi New Era
				pasca-restrukturisasi yang terjadi pada tahun 2021.
			</p>
			<!-- Tombol atau link -->
			<div class="flex flex-col space-y-4 sm:flex-row sm:space-y-0 space-x-3">
				<a href="http://ssk.jkt48.com/2024/id/vote" target="_blank"
					class="justify-center items-center py-3 px-5 text-base font-bold text-center text-white font-general-medium rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900">
					Gunakan Suaramu!
				</a>
			</div>
		</div>
	</div>

	<AppFooter class="items-center" />

</template>

<style scoped>
#bannersec {
	background: url(@/assets/images/background-images/oniel-ssk.jpg);
	width: 100vw;
	height: 100vh;
	/* Adjust the height as needed */
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

#default-font-page {
	font-family: 'Plus Jakarta Sans', sans-serif;
}
</style>

<!--
<a href="http://ssk.jkt48.com/2024/id" target="_blank"
							class="font-bold items-left px-14 py-4 rounded-full shadow-lg hover:shadow-xl bg-[#0093B9] hover:bg-[#5271FF] focus:ring-1 focus:ring-indigo-900 text-white text-xl duration-300"
							aria-label="BotOniel">
							<Button title="Access to General Election Website" />
                </a>
-->